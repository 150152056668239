import logo from '../assets/global/audio-garden-logo.webp';

import '../assets/global/global.scss';
import '../assets/home/home.scss';

import { Outlet } from "react-router-dom";

import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom'

const onClick = (e) => {
  e.currentTarget.parentElement.querySelector(".dropdown-menu").classList.toggle("show");
}

function App() {

  useEffect(() => {
    document.querySelectorAll(".dropdown-toggle").forEach((e) => {
      e.addEventListener("click", onClick)
    })
    return () => {
      document.querySelectorAll(".dropdown-toggle").forEach((e) => {
        e.removeEventListener("click", onClick)
      })
    }
  })
  const nextClosestSection = (e) => {
    if(!e) return;

    let parent = e
    do {
      parent = parent.parentElement
    } while(parent != null && !parent.classList.contains("sec") && !parent.classList.contains("navbar") && !parent.classList.contains("hero")) 
    
    let current = e.parentElement.nextSibling

    if(!current)
      return nextClosestSection(e.parent)

    window.scrollTo({ top: current.getBoundingClientRect().top })
  }

  const loc = useLocation()
  const create_nav_link = (name, link) => <a className={"nav-link" + (loc.pathname == link ? " active" : "")} aria-current="page" href={link}>{name}</a>
  const create_drop_link = (name, link) => <a className={"dropdown-item" + (loc.pathname == link ? " active" : "")} aria-current="page" href={link}>{name}</a>

  return (
    <>
    <nav className={"navbar navbar-expand-lg navbar-dark bg-dark navbar-sticky navbar-sticky-open"}>
      <div className="container">
        <a className="navbar-brand" href="/"><img src={logo} className={"logo"}/></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse px-5 d-flex justify-content-center" id="navbarNavAltMarkup">
        <ul className="navbar-nav d-flex justify-content-between nav-center px-5 w-100">
          <li className="nav-item">
            {create_nav_link("HOME", "/")}
          </li>
          <li className="nav-item">
            {create_nav_link("ABOUT", "/about")}
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">SERVICES</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">GEAR</a>
            <div class="dropdown-menu">
              {create_drop_link("Gear Stories", "/gear")}
              <div class="dropdown-divider"></div>
              {create_drop_link("Mics", "/mics")}
              {create_drop_link("Guitar Amps", "/amps")}
              {create_drop_link("Instruments", "/inst")}
              {create_drop_link("Consoles", "/consoles")}
              {create_drop_link("Outboard Processing", "/outboard")}
            </div>
        </li>
          <li className="nav-item">
            <a className="nav-link" href="/booking">BOOK NOW</a>
          </li>
         </ul>  
        </div>
      </div>
    </nav>
    <Outlet />

    <footer class="sec sec-dark sec-footer text-center d-flex align-items-center justify-content-center">
        <div className="col-md-2">
          <p>
            <strong>
              WE R NOT DONE DONT JUDGE --except Shari :)
            </strong>
          </p>
        </div>
    </footer>
    </>
  );
}

/* 
example:

startState : {
    #id : {
      opacity: 0;
    }
}

endState : {
    opacity: 1;
}

children : {

}

startPos:

endPos:

pos:

*/ 

export default App;
