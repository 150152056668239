import '../assets/gear/gear.scss';
import telefunken from "../assets/gear/telefunken_u47.jpeg";

function GearItem(props) {
    return <div>
        <h4 className="gear-title">
            {props.quantity && <span className="quantity">2 x</span>}
            <span className="vendor">{props.vendor}</span>
            <span className="model">{props.model}</span>
        </h4>
        {props.sub && 
        <h5 className="gear-subtitle">
            {props.sub}
        </h5>}
    </div>
}
export default function Gear() {
    return <>
       <div className="sec sec-stuffed sec-light">
        <div className="container">
            <div className="row">
                <h1 className="cursive">
                    Gear Stories
                </h1>
                <p>
                    At Audio Garden…<br></br>
                    Our Gear is Amazing!<br></br>
                    <br></br>
                    Yes we said amazing - and it took nearly 30 years to assemble it all!  With over 100 vintage mics, 200 plus amps and hundreds of amazing pieces of vintage outboard gear and instruments, Audio Garden truly is a garden of opportunity where you can grow a musical career.
                </p>
            </div>
            <div className="row">
                    <div className="col-md-9">
                        <h3>
                            <strong>MICS</strong>
                        </h3>
                        <GearItem 
                            vendor="Telefunken" 
                            model="U47"
                            sub="W PS FROM SYLVIA MASSEY JOHNNY CASH/TOM PETTY"></GearItem>
                        
                        <GearItem 
                            vendor="RCA" 
                            model="77D"
                            quantity="2"
                            sub="RIBBON MIC W ORIGINAL CANVAS BAGS"></GearItem>
                        
                        <GearItem 
                            vendor="Royer" 
                            model="R-121"
                            quantity="2"></GearItem>

                        <GearItem 
                            vendor="Neumann" 
                            model="KM84i"
                            quantity="2"
                            sub="RIBBON MIC W ORIGINAL CANVAS BAGS"></GearItem>


                    </div>
                    <div className="col-md-3">
                        <img className="w-100" src={telefunken}>
                        </img>
                    </div>
                </div>
            </div>
        </div>
    </>
}