import '../assets/about/about.scss';
import markRoom from '../assets/global/mark-room.JPG';
export default function About() {
    return <>
        <div className="sec sec-dark sec-stuffed sec-about-hero">
            <div className="sidebox-wrap">
                <div className="sidebox">
                    <div className="sidebox-inner">
                        <div className="row">
                            <div className="col-md-5">
                                <h2 className="sidebox-title">
                                    About <br></br>Audio Garden
                                </h2>
                                <p className="sidebox-copy">
                                Experience the finest in audio production at our premier recording studio in New Jersey, where cutting-edge technology meets expert craftsmanship to deliver exceptional sound quality for your creative projects.﻿
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sidebox-wrap">
                <div className="sidebox">
                    <div className="sidebox-inner">
                        <div className="row">
                            <div className="col-md-5">
                                <h2 className="sidebox-title">
                                    LOREM IPSUM
                                </h2>
                                <p className="sidebox-copy">
                                Experience the finest in audio production at our premier recording studio in New Jersey, where cutting-edge technology meets expert craftsmanship to deliver exceptional sound quality for your creative projects.﻿
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>
}