import React from 'react';
import reportWebVitals from './reportWebVitals';
import { hydrate, render } from "react-dom";

import Root from "./routes/root";
import HomeRoute from "./routes/home";
import AboutRoute from "./routes/about";
import GearRoute from "./routes/gear";
import BookingRoute from "./routes/booking";
import Error from "./routes/error";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const rootElement = document.getElementById("root");

const router = () => {
  
  const r = createBrowserRouter([
    {
      path: "/",
      element: <HomeRoute />
    },
    {
      path: "/about",
      element: <Root />,
      children: [
        {
          path: "",
          element: <AboutRoute />
        }
      ]
    },
    {
      path: "/gear",
      element: <Root />,
      children: [
        {
          path: "",
          element: <GearRoute />
        }
      ]
    },
    {
      path: "/booking",
      element: <Root />,
      children: [
        {
          path: "",
          element: <BookingRoute />
        }
      ]
    },
    {
      path: "*",
      element: <Root />,
      children: [
        {
          path: "*",
          element: <Error />
        }
      ]
    }
  ]); 
  return <RouterProvider router={r} />
}

if (rootElement.hasChildNodes()) {
  hydrate(router(), rootElement);
} else {
  render(router(), rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
