import logo from '../assets/global/audio-garden-logo.webp';
import '../assets/global/global.scss';
import '../assets/home/home.scss';

import img1 from '../assets/home/slider-img/1.JPEG';
import img2 from '../assets/home/slider-img/2.JPEG';
import img3 from '../assets/home/slider-img/3.JPEG';
import img4 from '../assets/home/slider-img/4.JPEG';
import tqrImg from '../assets/home/tqrImg.JPEG';

import headphoneImg from '../assets/home/HEADPHONES.svg';
import speakerImg from '../assets/home/SPEAKER.svg';
import noteImg from '../assets/home/MUSIC-NOTE.svg';
import consoleImg from '../assets/home/CONSOLE.svg';
import downArrow from '../assets/home/DOWN-ARROW.svg';
import la2 from '../assets/home/la-2.JPEG'
import { useEffect, useState, useRef } from 'react';

export default function Home() {
  const boxRef = useRef(null)

  const [logoCollapse, setLogoCollapse] = useState(false)

  const [delDir, setDelDir] = useState(0)
  const nextClosestSection = (e) => {
    if(!e) return;

    let parent = e
    do {
      parent = parent.parentElement
    } while(parent != null && !parent.classList.contains("sec") && !parent.classList.contains("navbar") && !parent.classList.contains("hero")) 
    
    let current = e.parentElement.nextSibling

    if(!current)
      return nextClosestSection(e.parent)

    window.scrollTo({ top: current.getBoundingClientRect().top })
  }

  useEffect(() => {
    const wheelHandler = (e) => e.deltaY > 0 ? setDelDir(1) : setDelDir(-1)
    
    const scrollHandler = () => {
      if(!boxRef.current) return
      let h = boxRef.current.getBoundingClientRect().top

        if(window.scrollY > h) {
          if(!logoCollapse)
            setLogoCollapse(true)
        }
        else if(window.scrollY < h) {
          if(logoCollapse)
            setLogoCollapse(false)
        }
    }
    window.addEventListener("scroll", scrollHandler)
    window.addEventListener("wheel", wheelHandler)

    return () => {
      window.removeEventListener("scroll", scrollHandler)
      window.removeEventListener("wheel", scrollHandler)
    }
  }, [logoCollapse, delDir])

  return (
    <>
    <nav className={"navbar navbar-expand-lg navbar-dark bg-dark navbar-sticky navbar-sticky-open"}>
      <div className="container">
        <a className="navbar-brand" href="/"><img src={logo} className={"logo" + (!logoCollapse ? " logo-collapse" : "")}/></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse px-5 d-flex justify-content-center" id="navbarNavAltMarkup">
        <ul className="navbar-nav d-flex justify-content-between nav-center px-5 w-100">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#">HOME</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about">ABOUT</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/gear">GEAR</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">SERVICES</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/booking">BOOK NOW</a>
          </li>
         </ul>  
        </div>
      </div>
    </nav>

    <div class="hero text-center">
      <div class="hero-sec">
      <div>
          <img src={logo} className={"logo" + (!logoCollapse ? " logo-collapse" : "")}/>
        </div>
        <div className="hero-bottom-black" ref={boxRef} >
          <div class="ticker-wrapper">
            <p>
                ARTIST SERVICES | REHEARSAL STUDIO | MUSIC AND RECORDING INSTRUCTION
            </p>	
            <p>
                ARTIST SERVICES | REHEARSAL STUDIO | MUSIC AND RECORDING INSTRUCTION
            </p>	
          </div>
          <img src={downArrow} className="down-arrow" onClick={(e) => {
            nextClosestSection(e.currentTarget);
          }}></img>
        </div>
      </div>
      </div>

      <div class="sec sec-dark sec-stuffed console-sec py-0">
        <div class="black-box">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-4">
                  <h1 class="cursive">
                      Our Space
                  </h1>
                </div>
                <div class="col-md-8">
                  <h3>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis orci nibh, viverra ut pretium vitae, convallis eget ex. In ut urna varius, vehicula elit non, aliquam diam. In ut urna varius, vehicula elit non, aliquam diam. In ut urna varius, vehicula elit non, aliquam diam.
                  </h3>
                </div>
              </div>
            </div>
              <div className="carousel">
                <img src={img1}></img>
                <img src={img2}></img>
                <img src={img3}></img>
                <img src={img4}></img>
              </div>
          </div>
      </div>
      
    <div class="sec sec-light tqr-sec sec-stuffed py-0">
      <div class="white-box">

        <div class="container">
          <div class="row align-items-start">
            <h1 class="cursive">
              Top-Quality Recording
            </h1>
            
            <div class="col-md-6">
                <p>
                Located in the dynamic heart of New Jersey, Audio Garden Recording Studio specializes in providing top-quality recording experiences for artists, musicians, podcasters, and audio aficionados. Driven by a passion for nurturing talent and realizing creative visions, we offer an extensive range of services, including live sound reinforcement, forensic audio, vintage equipment, and repair services.
                </p>
                <p>
                Our skilled team is dedicated to delivering professional, seamless audio solutions to meet your unique needs. Discover the Audio Garden difference and let us help bring your audio dreams to life. Contact us today to learn more or to book a session.
                </p>
              </div>
              <div class="col-md-6 justify-content-end d-flex">
                  <img src={tqrImg}></img>
              </div>
            </div>
          </div>
        </div>
    </div>


    <div class="sec sec-dark services-sec sec-stuffed">
      <div class="black-box">
        <div class="container">
          <div class="row align-items-start">
            <h1 class="cursive">
              Our Services
            </h1>
            <div class="row g-4 py-5 row-cols-1 row-cols-lg-4">
              <div class="feature col text-center">
                <img src={headphoneImg} />
                <h3>Recording</h3>
                <p>Top quality recording services featuring a large selection of musical instruments, amplifiers, vintage outboard processing gear and recording consoles.</p>
                <a href="#" class="icon-link">
                  LEARN MORE
                  <svg class="bi"></svg>
                </a>
              </div>
              <div class="feature col text-center">
              <img src={speakerImg} />
                <h3>Production</h3>
                <p>In house production team to assist with arrangements and production techniques.</p>
                <a href="#" class="icon-link">
                  Call to action
                  <svg class="bi"></svg>
                </a>
              </div>
              <div class="feature col text-center">
                <img src={noteImg} /> 
                <h3>Songwriting</h3>
                <p>In house songwriting teams for all types of music genres.</p>
                <a href="#" class="icon-link">
                  Call to action
                  <svg class="bi"></svg>
                </a>
              </div>
              <div class="feature col text-center">
                <img src={consoleImg} /> 
                <h3>Mix and Mastering</h3>
                <p>Mixing and Mastering services to get your audio in top quality for distribution.</p>
                <a href="#" class="icon-link">
                  Call to action
                  <svg class="bi"></svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sec sec-light sec-no-bg sec-stuffed py-0">
        <div class="white-box">
          <div class="container">
              <div class="row align-items-start">
                <div class="col-md-6">
                    <h1 class="cursive">
                      Gear Stories
                    </h1>
                    <h3 class="sub">
                      LEARN ABOUT WHERE OUR VINTAGE GEAR CAME FROM
                    </h3>
                    <p>
                    Located in the dynamic heart of New Jersey, Audio Garden Recording Studio specializes in providing top-quality recording experiences for artists, musicians, podcasters, and audio aficionados. Driven by a passion for nurturing talent and realizing creative visions, we offer an extensive range of services, including live sound reinforcement, forensic audio, vintage equipment, and repair services.
                    </p>
                    <p>
                    Our skilled team is dedicated to delivering professional, seamless audio solutions to meet your unique needs. Discover the Audio Garden difference and let us help bring your audio dreams to life. Contact us today to learn more or to book a session.
                    </p>
                    <button type="button" class="btn btn-primary">
                        Stories
                    </button> 
                  </div>
                  <div class="col-md-6 justify-content-end d-flex">
                      <img src={la2}></img>
                  </div>
                </div>
            </div>
        </div>
        </div>

    </div>
    <div class="sec sec-dark sec-proj">
      <div class="container">
          <div class="row align-items-start">
            <h1 class="cursive">
              Recent Projects
            </h1>
            <div class="d-flex flex-wrap">
                <div class="lightbox">
                  <div class="lightbox-img" style={{backgroundImage: "url(" + tqrImg+")"}}>
                  </div>
                  <div class="lightbox-inner">
                    <div class="lightbox-inner-content">
                      <h5>
                        <strong>ARTIST NAME</strong>
                      </h5>
                      <p>
                        Single / Album Name
                      </p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
    </div>
    <footer class="sec sec-dark sec-footer text-center d-flex align-items-center justify-content-center">
        <div className="col-md-2">
          <p>
            <strong>
              Lorem ipsum dolor sit amet, consectetuer 
            </strong>
          </p>
        </div>
    </footer>
    </>
  );
}

/* 
example:

startState : {
    #id : {
      opacity: 0;
    }
}

endState : {
    opacity: 1;
}

children : {

}

startPos:

endPos:

pos:

*/ 
