import { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

export default function Booking() {
    const [value, onChange] = useState(new Date());

    return <div className="sec container">
        <div className="row">
            <DateTimePicker onChange={onChange} value={value} />
        </div>
  </div>
}